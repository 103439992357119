'use client';

import React, { ReactElement } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, Mousewheel } from 'swiper/modules';
import { IProductCarousel } from './types';
import 'swiper/css/bundle';
import { Typography } from '@components/Typography';
import { HeadingStyles, HeadingTags } from '@components/Typography/Heading/types';
import Icon from '@components/Icon';
import clsx from 'clsx';
import styles from './styles/productCarousel.module.scss';
import ProductItem from './productItem';

const BASE_SPACE_MOBILE = 12;
const BASE_SPACE_DESKTOP = 16;

const ProductCarousel = ({ heading, productItems, showPrice, uuid }: IProductCarousel): ReactElement | null => {
    // We need unique ID, in case there are multiple sliders on a page
    const navNextButtonClass = `swiper-${uuid}-left`;
    const navPrevButtonClass = `swiper-${uuid}-right`;

    const breakpoints = {
        0: {
            slidesPerView: 2.3,
            spaceBetween: BASE_SPACE_MOBILE,
        },
        768: {
            slidesPerView: 3.3,
            spaceBetween: BASE_SPACE_MOBILE,
        },
        1024: {
            slidesPerView: 5.15,
            spaceBetween: BASE_SPACE_DESKTOP,
        },
    };

    if (!(Array.isArray(productItems) && productItems.length)) {
        return null;
    }

    return (
        <section data-mt-type={uuid}>
            <div className={styles.topBlock}>
                <Typography.Heading tag={heading.type as HeadingTags} style={HeadingStyles.H3}>
                    {heading.text}
                </Typography.Heading>
                <div className={styles.navWrapper}>
                    <button className={clsx(navPrevButtonClass, styles.navButton)}>
                        <Icon name={'chevron-left'} />
                    </button>
                    <button className={clsx(navNextButtonClass, styles.navButton)}>
                        <Icon name={'chevron-right'} />
                    </button>
                </div>
            </div>
            <Swiper
                style={{
                    '--swiper-scrollbar-sides-offset': '0px',
                }}
                className={styles.swiper}
                cssMode={true}
                modules={[Navigation, Scrollbar, Mousewheel]}
                scrollbar={{
                    draggable: true,
                }}
                breakpointsBase={'window'}
                centeredSlidesBounds={true}
                breakpoints={breakpoints}
                navigation={{
                    nextEl: '.' + navNextButtonClass,
                    prevEl: '.' + navPrevButtonClass,
                }}
                mousewheel={true}
            >
                {productItems.map((product) => (
                    <SwiperSlide key={product.id}>
                        <ProductItem item={product} showPrice={showPrice} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    );
};

export default ProductCarousel;
