'use client';

import React, { useState, useMemo } from 'react';
import ProductCard from '@components/ProductCard-2';
import { IProductCarouselItem } from './types';
import { productSwatches } from '@utils/productTools';

const ProductItem = ({ item, showPrice }: { item: IProductCarouselItem; showPrice: boolean }) => {
    const [selectedItem, setSelectedItem] = useState<string | null>(null);
    const { image, isYotpoEnabled, name, price, ratingSummary, urlKey, productItemUrl, uuid, isBestseller } = item;

    const swatches = useMemo(() => {
        const initialSwatches = productSwatches(productItemUrl, item.configurableOptions, item.productVariants);
        initialSwatches.forEach((initialSwatch) => {
            if (initialSwatch.id === selectedItem) {
                initialSwatch.active = true;
            }
        });

        return initialSwatches;
    }, [selectedItem]);

    const productImage = useMemo(() => {
        const variant = selectedItem && swatches.find(({ id }) => id === selectedItem);
        return variant?.mainImage || false;
    }, [swatches, selectedItem]);
    const newPrice = price && { ...price, showRange: true };

    const handleSwatchSelection = (id: string) => {
        setSelectedItem(id);
    };

    return (
        <ProductCard
            isNewVersion
            image={{
                ...image,
                fileReference: productImage || image.fileReference,
            }}
            name={name}
            price={showPrice ? newPrice : undefined}
            urlKey={urlKey}
            yotpoRating={{ average_score: ratingSummary }}
            isYotpoEnabled={isYotpoEnabled}
            swatches={swatches}
            handleSwatchSelection={handleSwatchSelection}
            uuid={uuid}
            isBestseller={isBestseller}
        />
    );
};

export default ProductItem;
